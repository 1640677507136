.login-container {
    padding-top: 45px;
    .panel-login {
        border-color: #ccc;
    	-webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
    	-moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
    	box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
      .loader {
        height: 4px;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: #ddd;
      }
      .loader:before{
        display: block;
        position: absolute;
        content: "";
        left: -200px;
        width: 200px;
        height: 4px;
        background-color: #2980b9;
        animation: loading 2s linear infinite;
      }
      @keyframes loading {
          from {left: -200px; width: 30%;}
          50% {width: 30%;}
          70% {width: 70%;}
          80% { left: 50%;}
          95% {left: 120%;}
          to {left: 100%;}
      }
      .panel-heading {
        padding: 10px 10px;
      }
    }
    .panel-login>.panel-heading {
    	color: #00415d;
    	background-color: #fff;
    	border-color: #fff;
    	text-align:center;
    }
    .panel-login>.panel-heading a{
    	text-decoration: none;
    	color: #666;
    	font-weight: bold;
    	font-size: 15px;
    	-webkit-transition: all 0.1s linear;
    	-moz-transition: all 0.1s linear;
    	transition: all 0.1s linear;
    }
    .panel-login>.panel-heading a.active{
    	color: #029f5b;
    	font-size: 18px;
    }
    .panel-login>.panel-heading hr{
    	margin-top: 10px;
    	margin-bottom: 0px;
    	clear: both;
    	border: 0;
    	height: 1px;
    	background-image: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0));
    	background-image: -moz-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
    	background-image: -ms-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
    	background-image: -o-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
    }
    .panel-login input[type="text"],.panel-login input[type="email"],.panel-login input[type="password"] {
    	height: 45px;
    	border: 1px solid #ddd;
    	font-size: 16px;
    	-webkit-transition: all 0.1s linear;
    	-moz-transition: all 0.1s linear;
    	transition: all 0.1s linear;
    }
    .panel-login input:hover,
    .panel-login input:focus {
    	outline:none;
    	-webkit-box-shadow: none;
    	-moz-box-shadow: none;
    	box-shadow: none;
    	border-color: #ccc;
    }
    .login-wrapper{
    	padding: 50px 10px;
    }
    .panel-account{
    	text-align: center;
    }
    .btn-login {
    	background-color: #48535D;
    	outline: none;
    	color: #fff;
    	font-size: 14px;
    	height: auto;
    	font-weight: normal;
    }
    .btn-login:hover,
    .btn-login:focus {
    	color: #fff;
    	background-color: #333;
    	border-color: #333;
    }
    .forgot-password {
    	text-decoration: underline;
    	color: #888;
    }
    .forgot-password:hover,
    .forgot-password:focus {
    	text-decoration: underline;
    	color: #666;
    }
}
