form.confirm {
  .actions {
    margin-top: 20px;
    text-align: right;
    button {
      margin-left: 10px;
      width: 70px;
    }
  }
}
