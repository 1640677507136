.account-container {
  .account-page-header {
  	margin: 10px;
  }
  .user-page-header {
  	padding-top: 5px;
  	margin-left: 20px;
  }
  .table {
  	margin-top: 20px;
  	height: 100%;
  	width: 100%;
  	margin-bottom: 40px;
  }
  .user-table-action {
   		cursor: pointer;
  }
}
