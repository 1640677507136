#demo-main {
  display: flex;

  .tool-bar {
    padding-bottom: 1.5em;
  }

  button {
    margin-right: 0.5em;
    border: solid 1px lightgray;
    padding: .125em .25em;
    border-radius: .25rem;
  }

  .symbolButton {
    margin-left: 1em;
  }
  .symbolButton img {
    width: 2em;
    height: auto;

  }

  .tool-status {
    margin-top: 1em;
  }

  .annotator-container {
    width: 70vw;
    height: 100%;

  }

  .sidebar {
    width: 26vw;
    height: 100%;
    padding: 1rem;

    textarea {
      width: 100%;
      height: 50em;
    }

    h2 {
      padding-top: 0;
      margin-top: 0;
    }
  }
}