.annotator-container {
  width: 100%;
  height: 100%;
}

.tool-bar {
  z-index: 2;
  margin: 0 auto;
  padding: 10px;
  text-align: center;

  .btn {
    margin: 3px;
  }
}

.canvas-container {
  margin: auto;
}

.thumbnailImage {
  display: relative;
  height: 40px;
}

.grid-symbol {
  position: relative;
  float: left;
  height: 85vh;
  width: 120px;
  overflow-y: scroll;
  z-index: 1;
  background-color: white;
  margin: auto;
  padding-top: 1vh;
  padding-bottom: 4vh;
  padding-left: 2vh;
  padding-right: 2vh;
}
