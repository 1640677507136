.main-div {
	overflow-y: auto;
	max-height: 90vh;
	margin: 10px;
	
  .consent-div {
	height: 10%;
	margin-top: 50px;
	margin-bottom: 10px;
	margin-right: 10px;
	margin-left: 10px;
  }
  .radius-div {
  	height: 60%;
  	span {
  		margin: 5px;
  	}
  	.table-div {
  		height: 60%;
  		margin: 10px;
  	}
  	.progress-div {
  		margin: 10px;
  	}
  	.saveConsent-div {
  		margin: 10px;
  	}
  	.savePartner-div {
		margin-top: auto;
		margin-bottom: 50px;
		margin-right: 10px;
		margin-left: 10px;
  	}
  }
}
